import React from "react"
import Layout from "../components/layout"
import CarInsurance from "../components/carInsurance"
import {
  CurrencyEuroIcon,
  EllipsisHorizontalIcon,
  RectangleGroupIcon,
  ShoppingBagIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid"
import SEO from "../components/seo"
const stats = [
  {
    name: " Collecting user data",
    stat: "71,897",
    icon: <UserGroupIcon className="h-10 w-10 text-blue-500" />,
  },
  {
    name: "Facilitating onboarding",
    stat: "58.16%",
    icon: <RectangleGroupIcon className="h-10 w-10 text-green-500" />,
  },
  {
    name: "Showcasing product features",
    stat: "24.57%",
    icon: <ShoppingBagIcon className="h-10 w-10 text-yellow-500" />,
  },
  {
    name: "Generating personalized price offers",
    stat: "24.57%",
    icon: <CurrencyEuroIcon className="h-14 w-14 text-red-500" />,
  },
  {
    name: "and Much more!",
    stat: "24.57%",
    icon: <EllipsisHorizontalIcon className="h-10 w-10 text-pink-500" />,
  },
]

const Onboarding = () => {
  return (
    <Layout showNavbar={true}>
      <SEO title="Onboarding" />
      <div className="relative mx-auto max-w-7xl pt-28 pb-20 px-4 sm:px-10">
        <div className=" py-20 flex flex-col space-y-10 max-w-xl">
          <span className=" max-w-xl text-7xl text-white font-bold">
            Interactive content generates 2x more conversions.
          </span>
        </div>
        <div className=" max-w-xl">
          <span className=" text-xl text-slate-100 max-w-">
            Boost your website's conversion rate by incorporating interactive
            elements from your digital app or product. When users engage with
            interactive content, they tend to stay longer and are more likely to
            convert.
          </span>
        </div>
        <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map(item => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 flex items-center space-x-4 shadow sm:p-6"
              >
                {item.icon && item.icon}
                <dt className=" text-2xl font-light text-gray-500">
                  {item.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
        <div className=" py-10 pt-10 sm:pt-20">
          <span className=" text-4xl font-bold text-slate-100">
            Try it out for yourself!
          </span>
        </div>
        <div>
          <CarInsurance />
        </div>
      </div>
    </Layout>
  )
}

export default Onboarding
