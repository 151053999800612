import React, { Fragment, useState, useEffect } from "react"
import licenseplate from "../images/plate.png"
import { Dialog, Transition } from "@headlessui/react"
import PricingForOnboarding from "./pricing"

import loadingspin from "../images/loading.svg"
import checkmarkwhite from "../images/checkmarkwhite.svg"
const CarInsurance = () => {
  const [currentStep, setCurrenStep] = React.useState(0)
  const [carNumber, setCarNumber] = React.useState("")
  const [isOpen, setIsOpen] = useState(true)

  function closeModal() {
    setIsOpen(false)
    setCurrenStep(3)
  }

  function openModal() {
    setIsOpen(true)
  }

  const [listItems, setListItems] = useState([
    {
      text: "Great, we found your vehicle 149DCD! Let's see when you need new insurance.",
    },
    {
      text: "There is information!",
    },
    {
      text: "We will immediately make a new insurance for your vehicle.",
    },
    {
      text: "It will take some time, we are looking for the right insurance for you!",
    },
    {
      text: "Thanks for waiting! As soon as we are ready, we will check the latest data.",
    },
  ])
  const [step1List, setStep1List] = useState([])
  useEffect(() => {
    const displayItems = () => {
      listItems.forEach((item, index) => {
        setTimeout(() => {
          console.log(item.text) // Replace with your desired UI update logic
          setStep1List(step1List => [...step1List, item.text])
        }, (index + 1) * 2000) // 2-second delay for each item
      })
    }

    if (currentStep == 1) {
      displayItems()
    }
  }, [listItems, currentStep])

  useEffect(() => {
    if (step1List.length === 5) {
      setTimeout(() => {
        setCurrenStep(2)
      }, 3000)
    }
  }, [step1List])

  const rendeerStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className=" flex flex-col items-center  space-y-6">
            <span className=" text-2xl">Enter your car license plate</span>
            <div className="relative mt-2 rounded-md shadow-sm w-48 h-16">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center  ">
                <img src={licenseplate} className=" h-16 rounded-l-md" />
              </div>
              <input
                className=" text-4xl  uppercase h-16 text-black text-center w-full pl-6 rounded-md"
                placeholder="123 ABC"
                value={carNumber}
                onChange={e => setCarNumber(e.target.value)}
              />
            </div>
            <button
              disabled={carNumber.length < 5}
              onClick={() => setCurrenStep(1)}
              type="button"
              className="rounded-full bg-indigo-600  px-10 py-2  text-2xl   text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-300 "
            >
              Next
            </button>
          </div>
        )
      case 1:
        // setTimeout(() => {
        //   setCurrenStep(2)
        // }, 2000)
        return (
          <div className=" flex flex-col items-center  space-y-6 ">
            <>
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full  h-[400px] max-w-md transform overflow-hidden rounded-2xl bg-slate-800 p-6 text-left align-middle shadow-xl transition-all text-slate-100">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl font-medium leading-6 "
                          >
                            We found your Car data{` `} 🚗
                          </Dialog.Title>
                          <ul className="  py-6 p-4 ">
                            {step1List.map((item, index) => (
                              <li key={index} className=" py-2">
                                <div className=" flex flex-row items-center space-x-2">
                                  {step1List.length - 1 == index ? (
                                    <img
                                      className=" h-4 w-4"
                                      src={loadingspin}
                                    />
                                  ) : (
                                    <img
                                      className=" h-4 w-4"
                                      src={checkmarkwhite}
                                    />
                                  )}{" "}
                                  <span> {item}</span>
                                </div>{" "}
                              </li>
                            ))}
                          </ul>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          </div>
        )
      case 2:
        return (
          <>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-800 p-6 text-left align-middle shadow-xl transition-all text-slate-100">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 "
                        >
                          We found your Car data{` `} 🚗
                        </Dialog.Title>
                        <div className=" flex flex-col py-4 space-y-4">
                          <div className="mt-2 gap-4 flex flex-row items-center">
                            <span className=" text-lg font-light w-52">
                              Start of policy
                            </span>
                            <input
                              className=" text-xl border p-2 rounded-lg text-slate-800 w-full"
                              type="date"
                            />
                          </div>
                          <div className="mt-2 gap-4 flex flex-row items-center">
                            <span className=" text-lg font-light w-52">
                              Type of usage
                            </span>
                            <span className="isolate inline-flex rounded-md shadow-sm">
                              <button
                                type="button"
                                className="relative inline-flex items-center rounded-l-md bg-slate-500 px-3 py-2 text-lg  text-slate-100 ring-1 ring-inset ring-slate-600 hover:bg-slate-600 focus:z-10"
                              >
                                Ordinary
                              </button>
                              <button
                                type="button"
                                className="relative -ml-px inline-flex items-center bg-slate-500 px-3 py-2 text-lg  text-slate-100 ring-1 ring-inset ring-slate-600 hover:bg-slate-600 focus:z-10"
                              >
                                Rent
                              </button>
                              <button
                                type="button"
                                className="relative -ml-px inline-flex items-center rounded-r-md bg-slate-500 px-3 py-2 text-lg  text-slate-100 ring-1 ring-inset ring-slate-600 hover:bg-slate-600 focus:z-10"
                              >
                                Taxi
                              </button>
                            </span>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-row justify-end py-4">
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-pink-500 px-4 py-2 text-lg font-medium text-slate-100 hover:bg-pink-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-pink-500 focus-visible:ring-offset-2"
                            onClick={closeModal}
                          >
                            Lets see offers
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </>
        )

      case 3:
        return (
          <div>
            <PricingForOnboarding />
          </div>
        )

      default:
        break
    }
  }
  return (
    <div className=" text-white flex flex-col items-center">
      {rendeerStep()}
    </div>
  )
}

export default CarInsurance
